/* ========================= */
/* HEADER */
/* ========================= */
  .app-header__content, .app-header__mobile-menu {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* background: rgba(255,255,255,0.6); */
  }

  .app-header__content,
  .app-header__logo,
  .app-header__mobile-menu {
    background: #2c3e50!important;
  }

  .app-header__content .dropdown-toggle::after {
    display: none;
  }

  /* HAMBURGER */
  .hamburger-inner, 
  .hamburger-inner::before, 
  .hamburger-inner::after,
  .hamburger.is-active .hamburger-inner, 
  .hamburger.is-active .hamburger-inner::before, 
  .hamburger.is-active .hamburger-inner::after{
    background-color: #c3c4c6;
  }

  /* HEADER LOGO */
  .app-container > .app-header > .app-header__logo > a {
    font-weight: 700;
  }
  .app-container.app-theme-gray > .app-header > .app-header__logo > a {
    color: rgba(255, 255, 255, 0.7);
  }
  .app-container > .app-header > .app-header__logo > a {
    color: rgba(0, 0, 0, 0.7);
  }
  .app-header__logo svg.logo-src {
    width: auto;
    height: auto;
  }

  /* LANGUAGE DROPDOWN */
  @media (max-width: 991.98px) {
    /* .app-header__content .show.dropdown .dropdown-menu.show {
        transform: none!important;
    } */
  }

/* ========================= */
/* MAIN */
/* ========================= */

  /* TOASTS */
  .toast {
    background-color: rgba(255, 255, 255, 0.85);
  }

/* ========================= */
/* SIDEBAR */
/* ========================= */

  .fixed-sidebar .app-sidebar {
    background: #2c3e50!important;
    height: 100dvh;
  }

  .app-sidebar,
  .closed-sidebar .app-sidebar {
    z-index: 1032;
  }

  @media screen and (max-width: 991px) {
    .sidebar-mobile-open .app-sidebar-mobile-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        background: rgba(0,0,0,0.2);
        z-index: 1031;
    }

    .app-container.sidebar-mobile-open {
      overflow: hidden;
      height: 100dvh;
      min-height: 100dvh;
    }

    /* html:has(.sidebar-mobile-open) {
      overscroll-behavior: none;
    } */
  }

  html {
    overflow-x: auto;
    /* overscroll-behavior-x: contain; */
    overscroll-behavior-y: none;  
  }

  .closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-sidebar {
    height: auto;
    min-height: calc(100dvh - 60px);
  }

  .app-sidebar .scrollbar-sidebar {
    height: auto;
    min-height: calc(100dvh - 60px);
  }

  /* MENU ITEM ICON */
  .closed-sidebar .app-sidebar .app-sidebar__inner .metismenu-icon {
    left: 40%;
  }

  /* MENU FOOTER ACTIONS */
  .closed-sidebar .app-sidebar:not(:hover) .menu-footer {
    display: none !important;
  }

  .vertical-nav-menu svg.metismenu-state-icon, 
  .vertical-nav-menu svg.metismenu-icon {
    text-align: center;
    /* width: 34px;
    height: 34px;
    line-height: 34px; */
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -17px;
    font-size: 1.5rem;
    opacity: .7;
    transition: color 300ms;
    padding: 5px; /* DJ 05Feb22 */
  }

  .vertical-nav-menu li a:hover svg.metismenu-icon {
    opacity: .6;
  }

  /* MENU ITEM DROPDOWN LINK */
  .vertical-nav-menu li a {
    margin: 2px 0 0;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  /* .vertical-nav-menu li a.active {
    font-weight: 700;
  } */

  .vertical-nav-menu li a.active svg.metismenu-state-icon {
    transform: rotate(-180deg);
  }

  .vertical-nav-menu li a.active + ul {
    opacity: 1;
    height: auto;
    padding: .5em 0 0 2rem;
    transition: 0.3s 0.1s;
    transition-property: opacity, padding;
  }

  .vertical-nav-menu li a + ul,
  .closed-sidebar .app-sidebar:not(:hover) li a.active + ul { /* CLOSE DROPDOWNS WHEN ITEM INACTIVE OR SIDEBAR CLOSED */
    opacity: 0;
    height: 0;
    padding: 0;
    /* overflow: hidden; */
  }

  /* MENU ITEM DROPDOWN ICON */
  .vertical-nav-menu svg.metismenu-state-icon {
    transition: transform 300ms;
    left: auto;
    right: 0;
  }

  /* MENU ITEM ACTIVE */
  .app-sidebar.sidebar-text-light .vertical-nav-menu li a.active:not(:hover),
  .app-sidebar.sidebar-text-light .vertical-nav-menu > li:has(ul > li > a.active) > a {
    background: rgba(255, 255, 255, 0.05);
  }

  /* MENU ITEM FOCUS (TAB) */
  .app-sidebar.sidebar-text-light .vertical-nav-menu li a:focus,
  .app-sidebar.sidebar-text-light .vertical-nav-menu li:has(a:focus) > a {
    background: rgba(255, 255, 255, 0.15);
  }

  /* OPEN DROPDOWN WHEN TAB FOCUSES LINK WITHIN */
  .app-sidebar.sidebar-text-light .vertical-nav-menu li:has(a:focus) > a.overflow-hidden + ul {
    opacity: 1;
    height: auto;
    padding: .5em 0 0 2rem;
    overflow: auto;
  }
  .app-sidebar.sidebar-text-light .vertical-nav-menu li:has(a:focus) > a.overflow-hidden > svg.metismenu-state-icon {
    transform: rotate(-180deg);
  }


/* ========================= */
/* FOOTER */
/* ========================= */


/* ========================= */
/* FORMS */
/* ========================= */
  .form-control:not(.noBorder):focus,
  .form-select:not(.noBorder):focus {
    /* border: 1px solid #3f6ad8 !important; */
    border: 1px solid #34495e !important;
    /* box-shadow: none; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; /* https://getcssscan.com/css-box-shadow-examples */
  }

  .transparentBorder {
    border: 1px solid rgba(0, 0, 0, 0) !important;
  }

  .transparentBg:not(:hover) {
    background: rgba(0, 0, 0, 0) !important;
  }

  /* INPUT GROUP VALIDATION BORDER RADIUS FIX */
  .input-group.has-validation > .form-control {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* REACT-TEL-INPUT-2 */
  .react-tel-input > .invalid-number:valid {
    background: none;
  }
  .react-tel-input > .invalid-number:valid:focus {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; /* overwrite validation style */
  }

/* ========================= */
/* BUTTONS */
/* ========================= */
  button.noCaret::after {
    display: none;
  }

/* ========================= */
/* MISC */
/* ========================= */
  :disabled,
  .disabled,
  [disabled] {
    cursor: not-allowed!important;
  }

  /* TOOLTIPS */
  .tooltip[role="tooltip"] {
    position: fixed; /* https://stackoverflow.com/a/75264190 */
    padding: 0;
  }

  /* SCROLL SWIPE OVERFLOW */
  html, body {
    /* overscroll-behavior-x: none; */
  }

/* ========================= */
/* REACT DATA TABLE (RDT) */
/* ========================= */
  .rdt_TableRow:hover {
    border-bottom-color: rgba(0, 0, 0, 0.12)!important;
    outline: none!important;
  }