.PhoneInputCountry {
  background-color: #eee !important;
  border: 1px solid #ced4da !important;
  border-right: 0 !important;
  margin: 0;
  padding: 0 0.5rem !important;
}

.bg-white.transparentBorder ~ .PhoneInput .PhoneInputCountry {
  background-color: white !important;
  border: 0 !important;
}

/* OVERRIDE VALIDATION */

.PhoneInputInput.form-control:valid.is-invalid {  /* make appear invalid */
  border-color: #d92550;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23dc3545%27 viewBox=%27-2 -2 7 7%27%3e%3cpath stroke=%27%23d9534f%27 d=%27M0 0l3 3m0-3L0 3%27/%3e%3ccircle r=%27.5%27/%3e%3ccircle cx=%273%27 r=%27.5%27/%3e%3ccircle cy=%273%27 r=%27.5%27/%3e%3ccircle cx=%273%27 cy=%273%27 r=%27.5%27/%3e%3c/svg%3E");
}
.PhoneInputInput.form-control:valid.is-invalid:focus {  /* make appear invalid */
  box-shadow: 0 0 0 .2rem rgba(217, 37, 80, 0.25);
}

.PhoneInputInput.form-control:invalid.is-valid {  /* make appear valid */
  border-color: #3ac47d;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328a745%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
}
.PhoneInputInput.form-control:invalid.is-valid:focus {  /* make appear valid */
  box-shadow: 0 0 0 .2rem rgba(58, 196, 125, 0.25);
}

/* END OVERRIDE VALIDATION */