/* INPUTS TEXTAREAS */
#ModalTask input:hover:not(:focus,[type=checkbox]),
#ModalTask textarea:hover:not(:focus) {
  background: #eee;
}

#ModalTask textarea:not(:focus, :disabled) {
  background: rgba(0, 0, 0, 0.01);
}

/* CHECKLISTS */
#ModalTask span:has(input[type=checkbox]:checked) + input[type=text]:not(:focus) {
  text-decoration: line-through;
  color: var(--muted);
}

#ModalTask .form-check-input {
  position: relative;
  margin-left: 0;
}

/* DATE PICKER */
#ModalTask .react-datepicker,
#ModalTask .react-datepicker__header  {
  margin: 0 auto;
  border-color: #eee;
}

#ModalTask .react-datepicker__navigation-icon {
  top: 5px;
}

#ModalTask .react-datepicker__month {
  margin: 0;
}

#ModalTask .accordion-collapse.collapse.d-flex:not(.show) {
  display: none !important;
}

/* TIME PICKER */
#ModalTask div:has( > .react-datepicker--time-only) {
  display: flex;
  margin-bottom: 0.25rem;
}

#ModalTask .react-datepicker--time-only .react-datepicker__triangle {
  transform: translate(47px, 0) !important;
}

/* MEMBERS / ASSIGNEES */
#ModalTask [data-letters]:before {
  font-size: 0.8rem;
  width: 3em;
  height: 3em;
  line-height: 3em;
}

#members {
  max-height: 15rem;
  overflow-y: scroll;
}

#members > form:not(:last-of-type) {
  margin-bottom: 0.25rem;
}

/* PHONE FLAGS */
.react-tel-input {
  width: auto;
}

.react-tel-input .flag {
  background-size: auto;
}

.react-tel-input .divider {
  margin-top: 0;
}

.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #eee !important;
}

.react-tel-input .selected-flag:hover {
  background: inherit !important;
}

.react-tel-input :disabled {
  background: #e9ecef!important;
}

/* ATTACHMENTS */
.list-group > .list-group-item.list-group-item-action > .dropdown-toggle {
  cursor: pointer;
}

.list-group > .list-group-item.list-group-item-action > .dropdown-toggle::after {
  display: none;
}

/* CLIENT DETAILS */
#clientDetailsToggle {
  height: 18px;
  width: 18px;
  padding: 0;
  line-height: 18px;
}