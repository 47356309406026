:root {
  --danger: #F76F8E;
  --info: #5AB1BB;
  --success: #04A777;
  --muted: #6c757d;
  
  --crystal_blue: rgb(90, 177, 187);
}

/* BACKGROUND */
.bg-crystal-blue {
  background-color: var(--crystal_blue) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

.bg-info {
  background-color: var(--info) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

/* TEXT */
.text-crystal-blue {
  color: var(--crystal_blue) !important;
}

.text-danger {
  color: var(--danger) !important;
}