/* DATE PICKER */
#newTaskModal .react-datepicker,
#newTaskModal .react-datepicker__header  {
  margin: 0 auto;
  border-color: #eee;
}

#newTaskModal .react-datepicker__navigation-icon {
  top: 5px;
}

#newTaskModal .react-datepicker__month {
  margin: 0;
}
