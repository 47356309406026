/* ========================= */
/* TRELLO STYLE */
/* ========================= */
#trello {
  overflow-x: auto;
  scrollbar-width: thin;
  /* scroll-snap-type: x mandatory; */
}

/* #trello:has(.dragging) {
  scroll-snap-type: none;
} */

/* SCROLLBARS */
#trello::-webkit-scrollbar,
#trello .bucketDropZone::-webkit-scrollbar {
  -webkit-appearance: none;
}
#trello::-webkit-scrollbar:vertical,
#trello .bucketDropZone::-webkit-scrollbar:vertical {
  width: 4px;
}
#trello::-webkit-scrollbar:horizontal,
#trello .bucketDropZone::-webkit-scrollbar:horizontal {
  height: 4px;
}
#trello::-webkit-scrollbar-thumb,
#trello .bucketDropZone::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.6); /* SHOULD MATCH BACKGROUND, CANT BE TRANSPARENT */
  background-color: rgba(0,0,0,0.2);
}

/* ========================= */
/* BUCKETS */
/* ========================= */
#trello > div {
  scroll-snap-align: center;
}

#trello .bucketCard {
  background: #f8f9fa !important;
}

/* TITLE */
#trello .bucketTitle {
  cursor: pointer;
  font-weight: 700;
}

#trello .bucketTitle:hover {
  background: rgba(0, 0, 0, 0.05);
}

#trello div.bucketTitle {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* TITLE DRAG ICON */
.bucketCard [data-rbd-drag-handle-draggable-id] {
  cursor: grab;
}

/* TITLE DROPDOWN */
#trello .dropdown-toggle.btn-link:hover {
  background: rgba(0, 0, 0, 0.05);
}

#trello .dropdown-toggle::after {
  display: none;
}

#trello .bucketDropZone {
  overflow-x: hidden !important;
  padding-bottom: 0 !important;
  scrollbar-gutter: stable;
}


/* ========================= */
/* TASKS */
/* ========================= */
#trello .taskCard {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#trello .taskCard:not(.dragging):hover {
  background: rgba(0, 0, 0, 0.05);
}

/* #trello a:has(.taskCard):last-of-type,
#trello a:has(.taskCard):last-of-type .card {
  margin-bottom: 0 !important;
} */

/* ICONS */
#trello :not(a, button, [draggable]) > svg[data-prefix^="fa"] {
  cursor: default;
}

/* ASSIGNEE INITIALS */
[data-letters] {
  cursor: default;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 0.75em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background: #FFEAD0;
  vertical-align: middle;
  color: inherit;
}

/* SHADOWS */
.top-box
{
    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
}
.left-box
{
    box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
}
.right-box
{
    box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.4);
}
.bottom-box
{
    box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.4);
}